import React, {useState} from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SectionHeader from '../components/section-header'
import ContentBlock from '../components/content-block'
import Row from '../components/row'
import styles from '../components/application.module.css'

function AboutPage(props) {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const correctPassword = 'recovery'
    const [errorState, setErrorState] = useState('hide')

    function handlePasswordSubmit(e) {
      e.preventDefault()
      const password = e.target.form[0].value.toLowerCase()
      if (password === correctPassword) {
        window.location.replace("https://hipaa.jotform.com/app/220025707060139")
      } else {
        setErrorState('show')
      }
    }

    return (
      <Layout location={props.location}>
          <Helmet title={siteTitle} />
          <div style={{minHeight: '50vh', padding: '5%'}}>
          <Row>
            <div>
              <h2>Scholarship Application Portal</h2>
              <p style={{maxWidth: '800px', textAlign: 'center', marginTop: '15px'}}>Welcome to the Ben Meyer Recovery Scholarship application portal! Upon entering the correct password below, you'll be taken to our HIPAA-compliant online application.</p>
              <form>
                <div className={styles.formDiv}>
                  <input type='text' id='password'/>

                    <button type='submit' onClick={(e) => handlePasswordSubmit(e)}>Submit</button>
                  </div>
              </form>
            </div>
          </Row>
          <Row>
          <p className={errorState==='hide' ? styles.hide : styles.show}>The password you entered was not correct. If you need assistance please contact your Foundation representative or email info@benmeyerfoundation.org</p>

          </Row>
          </div>

      </Layout>
    )
}

export default AboutPage

export const pageQuery = graphql`
  query ApplicationQuery {
    allContentfulContentBlock(filter: {page: {eq: "about"}}) {
      edges {
        node {
          id
          backgroundImage {
            file {
              url
            }
          }
          contentText {
            contentText
          }
          contentful_id
          link
          linkText
          title
          imagePosition
        }
      }
    }
  }

`
